
import * as Vue from 'vue';
import ContentWrapper from '@/layouts/ContentWrapper.vue';
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { useStream } from "@contrarian/ui-shared/lib/Stream"
import { useStore } from "vuex";
import OverlayHowItWorksVideo from '@/components/OverlayHowItWorksVideo.vue';
import Header from '@/components/Header.vue';

export default Vue.defineComponent({
  components: {
    ContentWrapper,
    OverlayHowItWorksVideo,
    Header,
  },
  data() {
    return {
      launchDate: new Date('07/01/2022').getTime(),
      time: {
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0',
      },
      count: 0,
      test: ''
    }
  },
  watch: {
    'time.seconds': {
      handler(newValue, oldValue) {
        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var now = new Date().getTime();
        var distance = this.launchDate - now;
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);

        setTimeout(() => {
          this.time.days = days + ''
          this.time.hours = hours + ''
          this.time.minutes = minutes + ''
          this.time.seconds = seconds + ''
        }, 1000)
      },
      immediate: true,
      deep: true
    }
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const stream = useStream();
    if(!store.state.session.siteVisitedWithCode && store.state.session.invite) {
        if (store.state.session.invite.isInPerson) {
          //If they have a isInPerson we know they are in-person, go straight to login
            stream.createInviteEvent(store.state.session.invite.id, "Opening Register - User Needs To Create Password")
            emitter.emit('openRegister');
        } else {
          //If they don't have a isInPerson we know they aren't in-person, show founding trader info
            stream.createInviteEvent(store.state.session.invite.id, "User is on Founding Trader Overlay becuase they are listed as not in-person")
            emitter.emit('openCardDetails');
          
        }
      
    }
    return {
      emitter,
    };
  },
  methods: {
  openRegister(event: MouseEvent) {
      event.stopImmediatePropagation();
      this.emitter.emit('openRegister');
    },
    openHowItWorksVideo() {
      this.emitter.emit('openHowItWorksVideo');
    },
    openFoundingTrader() {
      this.emitter.emit('openFoundingTrader');
    },
    goto(refName: any) {
      var element: any = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    }
  }
});
