import { createStore } from "vuex";
import { config } from '@/main';
import session from '@contrarian/ui-shared/store/session';

export default createStore({
  modules: {
    session,
  },
  state: {
    ...config,
  },
  mutations: {},
  actions: {},
});
