
import * as Vue from 'vue'
import OverlayHowItWorksVideo from '@/components/OverlayHowItWorksVideo.vue';
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";

export default Vue.defineComponent({
  components: {},
  setup() {
    const emitter = useEmitter();

    return {
      emitter,
    };
  },
  mounted() {

  },
  methods: {
    openHowItWorksVideo() {
      this.emitter.emit('openHowItWorksVideo');
    },
     openRegister(event: MouseEvent) {
      event.stopImmediatePropagation();
      this.emitter.emit('openRegister');
    },
  }
});
