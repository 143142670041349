<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeOverlay">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div v-if="invite">
              <h1 class="font-ammocorps text-center pt-3 mb-5">
                <div class="text-2xl text-slate-500">YOU'RE A VIP!</div>
                <div class="text-3xl">NO WAITLIST FOR YOU</div>
              </h1>
              <p>It seems you came to this website from a special <em>Free Box of Ammo</em> link. That means you can skip the waitlist and walk right in.</p>
              <button @click="openRegister" class="bg-blue-600 mt-5 text-white px-6 py-2 w-full">Let's Go</button>
            </div>

            <div v-else-if="isSubmitted" class="pb-5 px-5">
              <h1 class="font-ammocorps text-center pt-3 mb-5">
                <div class="text-2xl text-slate-500">YOU'RE ON THE LIST!</div>
              </h1>
              <p>Keep an eye out on <em>{{model.email}}</em>. We'll email you as soon as we have a spot. We can't wait for you to try out the Ammo Exchange. Thanks for your patience!</p>
            </div>

            <div v-else>
              <h1 class="font-ammocorps text-center pt-3 text-3xl">GET ON THE LIST!</h1>
              <p class="pb-2 text-left text-sm text-gray-500">Fill out the form below and we'll put you on our waitlist. We're adding new members as fast as possible, on a first-come, first-serve basis.</p>

              <div v-if="formError" class="form-error rounded-md bg-red-50 p-4 mb-3">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <ExclamationTriangleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      {{formError}}
                    </h3>
                  </div>
                </div>
              </div>

              <form class="space-y-6 w-5/5 mx-auto" @submit.native.prevent="addToWaitlist">
                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label for="firstName" class="block text-sm font-medium text-gray-400">
                      First Name
                    </label>
                    <div class="mt-1">
                      <input v-model="model.firstName" id="firstName" name="firstName" type="text" autocomplete="given-name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label for="lastName" class="block text-sm font-medium text-gray-400">
                      Last Name
                    </label>
                    <div class="mt-1">
                      <input v-model="model.lastName" id="lastName" name="lastName" type="text" autocomplete="family-name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>
                </div>
                <div>
                  <label for="email" class="block text-sm font-medium text-gray-400">
                    Email address
                  </label>
                  <div class="mt-1">
                    <input id="email" name="email" type="email" v-model="model.email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                  </div>
                </div>

                <div class="pt-3">
                  <button type="submit" class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{isSubmitting ? 'Adding to Waitlist' : 'Add to Waitlist'}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import * as Vue from 'vue';
import {useStore} from "vuex";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import inviteModule from "@contrarian/ui-shared/modules/inviteModule";
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import API from '@contrarian/ui-shared/lib/API';
import {useRouter} from "vue-router";

export default Vue.defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    ExclamationTriangleIcon,
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const router = useRouter();

    const isOpen = Vue.ref(false);
    const formError = Vue.ref('');
    const { invite, inviteCountdown } = inviteModule(store);

    emitter.on('openWaitlist', () => {
      isOpen.value = true
    });

    const model = Vue.reactive({
      firstName: '',
      lastName: '',
      email: '',
    });

    return {
      store,
      router,
      model,
      formError,
      isOpen,
      invite,
      isSubmitting: Vue.ref(false),
      isSubmitted: Vue.ref(false),
    }
  },
  methods: {
    async addToWaitlist() {
      this.formError = '';

      try {
        this.isSubmitting = true;
        await API.post('waitlist', this.model);
      } catch (error) {
        this.isSubmitting = false;
        return this.formError = error.title;
      }

      this.isSubmitting = true;
      this.isSubmitted = true;
    },
    closeOverlay() {
      if (this.forceOpen) return;
      this.isOpen = false;
    },
    openRegister() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openRegister'), 500);
    },
  }
});
</script>
