import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_OverlayLogin = _resolveComponent("OverlayLogin")!
  const _component_OverlayRegister = _resolveComponent("OverlayRegister")!
  const _component_OverlayFoundingTraderApplication = _resolveComponent("OverlayFoundingTraderApplication")!
  const _component_OverlayWaitlist = _resolveComponent("OverlayWaitlist")!
  const _component_OverlayCardDetails = _resolveComponent("OverlayCardDetails")!
  const _component_Alerts = _resolveComponent("Alerts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })),
    _createVNode(_component_OverlayLogin),
    _createVNode(_component_OverlayRegister),
    _createVNode(_component_OverlayFoundingTraderApplication),
    _createVNode(_component_OverlayWaitlist),
    _createVNode(_component_OverlayCardDetails),
    _createVNode(_component_Alerts)
  ], 64))
}