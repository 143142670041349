
import * as Vue from 'vue';
import NavForPublic from "./nav/NavForPublic.vue";
import ContentWrapper from "./ContentWrapper.vue";
import Footer from "./Footer.vue";

export default Vue.defineComponent({
  components: {
    ContentWrapper,
    Footer,
    NavForPublic
},
  props: {
    ContentWrapper: Boolean,
    navIsDarkTheme: Boolean,
  },
  setup() {
    return {}
  }
});
