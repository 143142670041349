
  import * as Vue from 'vue';
  import {useStore} from "vuex";
  import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
  import inviteModule from "@contrarian/ui-shared/modules/inviteModule";
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { GiftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/20/solid'
  import { XMarkIcon } from '@heroicons/vue/24/outline'

  export default Vue.defineComponent({
    components: {
      XMarkIcon,
      GiftIcon,
      ChevronDoubleRightIcon,
      Disclosure, 
      DisclosureButton, 
      DisclosurePanel,
    },
    props: {
      isDarkTheme: Boolean,
    },
    setup() {
      const emitter = useEmitter();
      const store = useStore();

      const platformDomain = process.env.VUE_APP_PLATFORM_DOMAIN || '';
      const { memberId } = store.state.session;
      const { invite, inviteCountdown } = inviteModule(store);
      
      if (invite) {
        invite.isValid = inviteCountdown && inviteCountdown.value.totalMillis > 0 && !invite.hasBeenRedeemed;
      }

      if (invite?.hasBeenRedeemed || inviteCountdown && inviteCountdown.value.totalMillis <= 0) {
        store.commit('session/clearInvite');
      }

      return {
        emitter,
        store,
        appId: process.env.VUE_APP_ID,
        invite,
        inviteCountdown,
        platformDomain,
        memberId,
        showFoundingTraderButton: Vue.ref(false),
      }
    },
    mounted() {
      window.addEventListener('scroll', this.tryShowFreeAmmoButton.bind(this)); 
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.tryShowFreeAmmoButton.bind(this)); 
    },
    methods: {
      tryShowFreeAmmoButton(event: any) {
        this.showFoundingTraderButton = (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50);
      },
      openLogin() {
        this.emitter.emit('openLogin');
      },
      openRegister(event: MouseEvent) {
        event.stopImmediatePropagation();
        this.emitter.emit('openRegister');
      },
      openCardDetails() {
        this.emitter.emit('openCardDetails');
      },
      openWaitlist() {
        this.emitter.emit('openWaitlist');
      },
      clearInvite() {
        this.invite = undefined;
        this.store.commit('session/clearInvite');
      }
    }
  });
