<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeOverlay">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div v-if="isSubmitted">
              <h1 class="font-ammocorps text-center pt-3 text-3xl">YOUR APPLICATION HAS BEEN SUBMITTED</h1>
              <p class="pb-7 text-center text-sm text-gray-500">
                We will review your application as soon as possible. Keep an eye out on <em>{{model.email}}</em>. We're excited to have you try out the Ammo Exchange!
              </p>
              <div class="pt-3">
                <button @click="closeOverlay" class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Close Overlay
                </button>
              </div>
            </div>
            <div v-else>
              <h1 class="font-ammocorps text-center pt-3 text-3xl">FOUNDING TRADER APPLICATION</h1>
              <p class="pb-7 text-center text-sm text-gray-500">
                We're handpicking a select group of Founding Traders from across America. Fill out this brief application and we'll be in touch.
              </p>

              <div v-if="formError" class="form-error rounded-md bg-red-50 p-4 mb-3">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <ExclamationTriangleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      {{formError}}
                    </h3>
                  </div>
                </div>
              </div>

              <form class="space-y-6 w-5/5 mx-auto" @submit.native.prevent="submitApplication">
                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label for="firstName" class="block text-sm font-medium text-gray-400">
                      First Name
                    </label>
                    <div class="mt-1">
                      <input v-model="model.firstName" id="firstName" name="firstName" type="text" autocomplete="given-name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label for="lastName" class="block text-sm font-medium text-gray-400">
                      Last Name
                    </label>
                    <div class="mt-1">
                      <input v-model="model.lastName" id="lastName" name="lastName" type="text" autocomplete="family-name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>
                </div>

                <div>
                  <label for="email" class="block text-sm font-medium text-gray-400">
                    Email address
                  </label>
                  <div class="mt-1">
                    <input id="email" name="email" type="email" v-model="model.email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                  </div>
                </div>

                <div>
                  <label for="requestedAmount" class="block text-sm font-medium text-gray-400">
                    Requested Allocation Amount
                  </label>
                  <div class="mt-1">
                    <select id="requestedAmount" name="requestedAmount" v-model="model.requestedAmount" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value="100">$100</option>
                      <option value="500">$500</option>
                      <option value="1000" selected>$1,000</option>
                      <option value="5000" selected>$5,000</option>
                      <option value="10000" selected>$10,000</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label for="comments" class="block text-sm font-medium text-gray-400">
                    Why Are You Interested?
                  </label>
                  <textarea class="w-full" id="comments" name="comments" v-model="model.comments"></textarea>
                </div>

                <div class="flex flex-col space-y-5">
                  <SwitchGroup as="div" class="flex space-x-4">
                    <Switch v-model="model.isUsCitizen" :class="[
                      model.isUsCitizen ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    ]">
                      <span class="sr-only">Use setting</span>
                      <span aria-hidden="true" :class="[
                        model.isUsCitizen ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                      ]" />
                    </Switch>
                    <SwitchLabel>I Am An American Citizen</SwitchLabel>
                  </SwitchGroup>
                  <SwitchGroup as="div" class="flex space-x-4">
                    <Switch v-model="model.hasFFL" :class="[
                      model.hasFFL ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    ]">
                      <span class="sr-only">Use setting</span>
                      <span aria-hidden="true" :class="[
                      model.hasFFL ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                      ]" />
                    </Switch>
                    <SwitchLabel>I Have a Federal Firearms License</SwitchLabel>
                  </SwitchGroup>
                  <SwitchGroup as="div" class="flex space-x-4">
                    <Switch v-model="model.isMilitaryFirstResponder" :class="[
                      model.isMilitaryFirstResponder
                        ? 'bg-indigo-600'
                        : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    ]">
                      <span class="sr-only">Use setting</span>
                      <span aria-hidden="true" :class="[
                        model.isMilitaryFirstResponder
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                      ]" />
                    </Switch>
                    <SwitchLabel>I Am a First Responder/Military</SwitchLabel>
                  </SwitchGroup>
                </div>

                <div class="pt-3">
                  <button type="submit" class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{isSubmitting ? 'Submitting Application' : 'Submit Application'  }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import * as Vue from 'vue';
import {useStore} from "vuex";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import {useRouter} from "vue-router";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import API from '@contrarian/ui-shared/lib/API';

export default Vue.defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    ExclamationTriangleIcon,
    Switch,
    SwitchLabel,
    SwitchGroup,
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const router = useRouter();

    const isOpen = Vue.ref(false);
    const formError = Vue.ref('');

    emitter.on('openFoundingTrader', () => {
      isOpen.value = true
    });

    const model = Vue.reactive({
      firstName: '',
      lastName: '',
      email: '',
      requestedAmount: '1000',
      isUsCitizen: false,
      isMilitaryFirstResponder: false,
      hasFFL: false,
      comments: '',
    });

    return {
      store,
      router,
      model,
      formError,
      isOpen,
      isSubmitting: Vue.ref(false),
      isSubmitted: Vue.ref(false),
    }
  },
  methods: {
    async submitApplication() {
      this.formError = '';
      
      try {
        this.isSubmitting = true;
        console.log(this.model)
        await API.post('foundingTrader/application', this.model);
      } catch (error) {
        this.isSubmitting = false;
        console.log(error);
        return this.formError = error.title;
      }

      this.isSubmitting = true;
      this.isSubmitted = true;
    },
    closeOverlay() {
      if (this.forceOpen) return;
      this.isOpen = false;
    },
    openRegister() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openRegister'), 500);
    },
  }
});
</script>
