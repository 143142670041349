<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeOverlay">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-6 sm:py-6 sm:px-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
            <div v-if="invite.isValid" class="font-light">
              <h1 class="text-center pt-3 mb-2">
                <div class="text-4xl font-ammocorps ">
                  <template v-if="invite.firstName">WELCOME, {{invite.firstName}}!</template>
                  <template v-else>WELCOME</template>
                </div>
              </h1>
              <div class="font-light text-2xl border-t border-b border-slate-300 pt-3 pb-2">
                It Looks Like Someone Gave
                You A Founding Trader Card!
              </div>
              <p class="mt-5 ">Founding Trader cards are quite rare. There will never be more than 1,776... ever... and you snagged one. Congrats! Here's how they work...</p>

              <p class="mt-3">The U.S. Ammo Exchange launches on July 1st, 2022, and we already have a waitlist. However, Founding Trader Cards skip this waitlist, plus they get some really cool extra perks.</p>

              <ul class="flex flex-col space-y-3 mt-5 ml-5">
                <li class="flex flex-row">
                  <div class="w-6 h-6 border border-gray-400 relative mr-5 mt-2">
                    <CheckIcon class="absolute -top-3 -left-3 w-12 h-12 stroke-[3] stroke-green-600" />
                  </div>
                  <div>
                    <header class="font-bold">Skip the Waitlist</header>
                    <div>Get a head start by creating your USAE account today.</div>
                  </div>
                </li>
                <li class="flex flex-row">
                  <div class="w-6 h-6 border border-gray-400 relative mr-5 mt-2">
                    <CheckIcon class="absolute -top-3 -left-3 w-12 h-12 stroke-[3] stroke-green-600" />
                  </div>
                  <div>
                    <header class="font-bold">Your 1st Box of Ammo Is Free</header>
                    <div>Pick from one of five calibers during sign up (9mm, 223, etc)</div>
                  </div>
                </li>
                <li class="flex flex-row">
                  <div class="w-6 h-6 border border-gray-400 relative mr-5 mt-2">
                    <CheckIcon class="absolute -top-3 -left-3 w-12 h-12 stroke-[3] stroke-green-600" />
                  </div>
                  <div>
                    <header class="font-bold">Access to pre-IPO Ammo Pricing</header>
                    <div>Add additional ammo boxes at 10% below the lowest retail price</div>
                  </div>
                </li>
              </ul>

              <p class="mt-3">Your Founding Trader Card expires 48 hours after you receive it. You must sign up before the link expires, otherwise your spot will be given to someone else.</p>
              <div class="flex flex-row space-x-2 mt-5">
                <button @click="closeOverlay" class="bg-slate-400 hover:bg-slate-500 text-white px-6 py-2 w-full focus:outline-none focus:ring-0">Close Overlay</button>
                <button @click="openRegister" class="bg-indigo-400 hover:bg-indigo-500 text-white px-6 py-2 w-full focus:outline-none focus:ring-0">Become a Founding Trader</button>
              </div>
            </div>
            <div v-else-if="invite.hasBeenRedeemed">
              <div class="text-3xl">
                THIS INVITE HAS ALREADY BEEN REDEEMED
              </div>
              <p>
                Founding Trader invites can only be used once. Either you or someone else has already used this invite code
                to signup. Please contact the person who gave you this card if you think there has been a mistake.
              </p>
            </div>
            <div v-else>
              <div class="text-3xl">
                  THIS INVITE HAS EXPIRED
              </div>
              <p>
                Founding Trader invites are only valid for 48 hours. Please contact the person who gave you this invite 
                if you think this is a mistake.
              </p>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import * as Vue from 'vue';
import {useStore} from "vuex";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import inviteModule from "@contrarian/ui-shared/modules/inviteModule";
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import API from '@contrarian/ui-shared/lib/API';
import {useRouter} from "vue-router";

export default Vue.defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    ExclamationTriangleIcon,
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const router = useRouter();

    const isOpen = Vue.ref(false);
    const { invite, inviteCountdown } = inviteModule(store);

    if (invite) {
      invite.isValid = inviteCountdown && inviteCountdown.value.totalMillis > 0 && !invite.hasBeenRedeemed;
    }
    
    emitter.on('openCardDetails', () => {
      isOpen.value = true,
      store.commit('session/setSiteVisitedWithCode', 'true');
    });

    return {
      store,
      router,
      isOpen,
      invite,
    }
  },
  methods: {
    closeOverlay() {
      if (this.forceOpen) return;
      this.isOpen = false;
    },
    openRegister() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openRegister'), 500);
    },
  }
});
</script>
